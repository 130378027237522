$jobster-blue: #2e3c4d;
$jobster-light-blue: #a8c1de;
$jobster-gray: #f4f4f4;
$jobster-light-gray: #f5f5f6;
$jobster-dark-gray: #dbdbdb;
@import "./components/button.scss";
@import "./input.scss";
@import "./search.scss";
@import "./table.scss";

body {
    color: $jobster-blue !important;
}

.text-dark-gray {
    color: $jobster-dark-gray !important;
}

.text-blue {
    color: $jobster-blue !important;
}

.bg-light-gray {
    background-color: $jobster-light-gray !important;
}

.bg-gray {
    background-color: $jobster-dark-gray !important;
}

.bg-green {
    background-color: #addbb2 !important;
}

.bg-red {
    background-color: #faaaa5 !important;
}

.bg-yellow {
    background-color: #ffd191 !important;
}

.bg-light-blue {
    background-color: $jobster-light-blue;
    color: $jobster-blue;
}

.bg-blue {
    background-color: $jobster-blue;
    color: white;
}

.large-icon {
    font-size: 75px;
    color: $jobster-blue;
}

.rounded-15 {
    border-radius: 15px;
}

.cursor-pointer {
    cursor: pointer;
}

.ant-alert-message,
.ant-typography:not(.text-white, .text-danger, .text-success, a) {
    color: $jobster-blue !important;
}

// Ant alert
.ant-alert-success {
    .ant-alert-message {
        color: #378224 !important;
    }
}

.ant-alert.ant-alert-error {
    .ant-alert-message {
        color: #ce4242 !important;
    }
}

// Fade in
.fade-in-enter {
    opacity: 0;
    transform: scale(0.9);
}
.fade-in-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 1000ms, transform 1000ms;
}
.fade-in-exit {
    opacity: 1;
}
.fade-in-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 1000ms, transform 1000ms;
}
