input.ant-input,
.ant-input-affix-wrapper,
input.ant-input-number-input {
    border: none;
    border-radius: 0;
    padding: 8px 7px !important;
    background-color: $jobster-gray !important;
}

.ant-input-number,
input.ant-input-number-input {
    height: 38px;
}

.ant-form-item-has-error .ant-input-affix-wrapper {
    .ant-input,
    .ant-input:focus {
        box-shadow: none !important;
    }
}
.ant-form-item-has-error {
    .ant-input,
    .ant-input:focus,
    .ant-input-number,
    .ant-input-affix-wrapper {
        border: none;
        border-radius: 0;
        box-shadow: 0 0 0 2px #ff4d4f !important;
    }
}
.ant-input-number:focus,
.ant-input-number-focused,
.ant-input-affix-wrapper-focused {
    border: none;
    border-radius: 0 !important;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px #0f62fe !important;
}
.ant-input-affix-wrapper {
    border: none !important;
    padding: 0 11px 0 0 !important;
}
.ant-input-number:hover,
.ant-input-affix-wrapper,
.ant-input-affix-wrapper:hover {
    border: none;
    box-shadow: none;
    border-right-width: unset;
}
input.ant-input:focus,
input.ant-input:focus-visible {
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px #0f62fe;
}

.ant-form-vertical .ant-form-item-label {
    padding: 0 !important;
    label {
        color: $jobster-blue !important;
    }
}

.ant-input-number-handler-wrap {
    visibility: hidden;
}

/* Chrome autofill color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $jobster-gray inset !important;
}

// Input.Search
.ant-input-search {
    .ant-input {
        height: 32px !important;
    }
    .ant-input-search-button {
        background-color: $jobster-gray !important;
    }
}

.ant-input-search-small {
    .ant-input {
        height: 24px !important;
    }
}

.ant-input-search-large {
    input.ant-input[placeholder] {
        text-align: center;
        height: 41px !important;
    }
    .ant-input-search-button {
        height: 41px !important;
        padding-bottom: 3px !important;
        background-color: $jobster-blue !important;
        border-color: $jobster-blue !important;
    }
}