@import '../App.scss';

.ant-btn-link:not([disabled]),
.ant-table-filter-trigger.active,
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active,
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
    color: $jobster-blue !important;
}
.ant-btn-link{
    height: 30px !important;
}
.ant-btn-primary {
    border-color: $jobster-blue !important;
    background: $jobster-blue !important;
    height: 30px !important;
    padding: 0 30px !important;
    font-size: 14px !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    font-weight: 500 !important;
    transition: 0.5s !important;
}
.ant-btn-sm {
    border-radius: 0 !important;
}
.ant-btn-primary:hover {
    border: 1px solid $jobster-light-blue !important;
    background-color: $jobster-light-blue !important;
    color: $jobster-blue !important;
}

