.jobster-btn {
    border: 1px solid $jobster-blue;
    font-weight: 500;
    transition: 0.5s;
}

.jobster-btn-sm {
    height: 30px;
    padding: 0 30px;
    font-size: 14px;
}

.jobster-btn-md {
    height: 40px;
    padding: 0 40px;
    font-size: 18px;
}

.jobster-btn-lg {
    height: 50px;
    padding: 0 40px;
    font-size: 22px;
}

.jobster-btn-white {
    background-color: white;
    color: $jobster-blue;
}

.jobster-btn-blue {
    background-color: $jobster-blue;
    color: white;
}

.jobster-btn:not([disabled]):hover {
    border: 1px solid $jobster-light-blue;
    background-color: $jobster-light-blue;
    color: $jobster-blue;
}

.jobster-btn[disabled] {
    border: 1px solid #bababa;
    background-color: #bababa;
    color: white;
}

.jobster-btn[disabled]:hover {
    cursor: no-drop;
}
