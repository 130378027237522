.ant-select-selector {
    border: none !important;
    border-radius: 0 !important;
    background-color: $jobster-gray !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
.ant-select-selector {
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px #0f62fe !important;
    border: none !important;
}