// Table
.ant-table-thead > tr > th {
    background-color: $jobster-gray !important;
}
// Pagination
.ant-pagination-item-active a {
    color: #1890ff !important;
}

.ant-pagination-item {
    margin: 0 !important;
    border: none !important;
    a {
        margin-top: 3px !important;
    }
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
    margin: 0 !important;
    button {
        border: none !important;
    }
}

.ant-table-pagination.ant-pagination {
    margin-top: 0 !important;
}