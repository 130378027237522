@import '../../App.scss';
.modal-with-alert {
    .ant-modal-header {
        padding: 20px 24px;
        .ant-modal-title {
            color: $jobster-dark-gray;
        }
    }
    
    .ant-modal-header, .ant-modal-footer {
        border: none !important;
        text-align: center !important;
        margin-top: 30px;
    }

    .ant-modal-body {
        position: relative;
    }

    .modal-alert-container {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 100;
    }
}